<template>
  <div class="qualifications">
    <div class="content">
      <h1>荣誉资质</h1>
      <div>
        <!-- <div class="left">
          <img :src="require('@/assets/img/About/qualifications/1@2x.png')" />
        </div> -->
        <div class="listContent">
          <div v-for="(item, index) of imgList" :key="index" class="item">
            <img :src="item" :key="item" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryPageManageList } from "@/api/api.js";
export default {
  name: "qualifications",
  data() {
    return {
      imgList: [],
    };
  },
  created() {
    queryPageManageList({ pageName: "企业资质" }).then((res) => {
      if (res.data.rows[0]) {
        this.imgList = res.data.rows[0].pagePicture.split(",");
      }
    });
  },
};
</script>

<style lang="less" scoped>
@media screen and(min-width: 1000px) {
  .qualifications {
    .content {
      width: 80%;
    }
  }
}
@media screen and(max-width: 1000px) {
  .qualifications {
    .content {
      width: 100%;
    }
  }
}

.reduction {
  transform: scale(1);
  overflow: hidden;
  transition: 0.5s;
}
.enlarge {
  transform: scale(1.15);
  transition: 0.5s;
  overflow: hidden;
}

.qualifications {
  width: 100%;
  padding: 40px;
  .content {
    margin: auto;
    img {
      .reduction();
      width: 100%;
      height: 100%;
    }
    img:hover {
      .enlarge();
    }
    h1 {
      color: #d20505;
      font-weight: bold;
      text-align: center;
    }
    > div {
      // display: flex;
      // > .left {
      //   width: 25%;
      //   overflow: hidden;
      // }
      > .listContent {
        > div {
          overflow: hidden;
          > img {
            width: 100%;
            height: 100%;
            // height: 227px;
          }
        }
        // width: 75%;
        width: 100%;
        display: grid;
        // grid-template-columns: repeat(3, 33.33%);
        grid-template-columns: repeat(3, 33%);
        column-gap: 1%;
        row-gap: 15px;
      }
    }
  }
}
</style>
